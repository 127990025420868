import { createStore } from 'vuex'
import home from './module/home'
import tenant from './module/tenant'
import tool from './module/tool'

export default createStore({
  // 全局参数 this.$store.state.xxx
  // state: {},
  // set方法 this.$store.commit('setName', "test")
  // mutations: {},
  // get方法 this.$store.getters.getName
  // getters: {},
  // 异步方法 this.$store.dispatch(‘xxx’)
  // actions: {},
  modules: {
    tenant: tenant,
    home: home,
    tool: tool
  }
})

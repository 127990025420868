import Layout from '@/layout'
import store from '@/store/index'
import { createRouter, createWebHistory } from 'vue-router'

export const constantRoutes = [
  {
    path: '/',
    name: 'Hall',
    component: () => import('@/views/hall'),
    meta: {
      title: 'O(∩_∩)O~ 欢迎回来 ~(*｀∀´*)ノ亻!',
      auth: false
    }
  },
  {
    path: '/hall/detail',
    name: 'HallDetail',
    component: () => import('@/views/hall/detail'),
    meta: {
      title: '文章预览'
    }
  },
  {
    path: '/tools',
    name: 'ToolsHome',
    component: () => import('@/views/tools'),
    meta: {
      title: '工具箱'
    }
  },
  {
    path: '/tools/json',
    name: 'ToolJson',
    component: () => import('@/views/tools/json'),
    redirect: '/tools/json/analysis',
    children: [
      {
        path: '/tools/json/analysis',
        name: 'JsonAnalysis',
        component: () => import('@/views/tools/json/analysis'),
        meta: {
          title: 'JSON 在线解析'
        }
      },
      {
        path: '/tools/json/Compression',
        name: 'JsonCompression',
        component: () => import('@/views/tools/json/compression'),
        meta: {
          title: 'JSON 压缩/解压'
        }
      }
    ]
  },
  {
    path: '/docs',
    name: 'DocsHome',
    component: () => import('@/views/docs'),
    meta: {
      title: '开发文档'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
    meta: {
      title: '用户登录',
      auth: false
    }
  },
  {
    path: '/home/article/notes/write',
    name: 'WriteNotes',
    component: () => import('@/views/article/notes/write'),
    meta: {
      title: '编写笔记',
      auth: true
    }
  },
  {
    path: '/home/article/notes/preview',
    name: 'PreviewNotes',
    component: () => import('@/views/article/notes/preview'),
    meta: {
      title: '预览内容',
      auth: true
    }
  },
  {
    path: '/home/article/manuscript/redact',
    name: 'RedactManuscript',
    component: () => import('@/views/article/manuscript/redact'),
    meta: {
      title: '编辑草稿',
      auth: true
    }
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/home/dashboard',
    meta: { auth: true },
    children: [
      {
        path: '/home/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard'),
        meta: {
          title: '管理中心'
        }
      },
      {
        path: '/home/article/notes',
        name: 'ArticleNotes',
        component: () => import('@/views/article/notes'),
        meta: {
          title: '笔记管理'
        }
      },
      {
        path: '/home/article/manuscript',
        name: 'ArticleManuscript',
        component: () => import('@/views/article/manuscript'),
        meta: {
          title: '草稿管理'
        }
      },
      {
        path: '/home/article/catalog',
        name: 'ArticleCatalog',
        component: () => import('@/views/article/catalog'),
        meta: {
          title: '目录管理'
        }
      },
      {
        path: '/home/article/tag',
        name: 'ArticleTag',
        component: () => import('@/views/article/tag'),
        meta: {
          title: '标签管理'
        }
      },
      {
        path: '/home/resource/recycle',
        name: 'ResourceRecycle',
        component: () => import('@/views/resource/recycle'),
        meta: {
          title: '资源回收'
        }
      },
      {
        path: '/home/resource/image',
        name: 'ResourceImage',
        component: () => import('@/views/resource/image'),
        meta: {
          title: '图片管理'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes
})

// 路由守卫 to要跳转到的路径 from从哪个路径来 next往下执行的回调
router.beforeEach((to, from, next) => {
  // 页面滚动条置顶
  window.scrollTo(0, 0)
  // 404页面
  if (to.matched.length === 0) {
    next({ path: '/' })
  }
  // 页面标题
  if (to.meta.title) {
    document.title = to.meta.title + ' - 凤凰集'
  }
  // 判断该页面是否需要登录
  if (to.meta.auth) {
    // 在localStorage中获取token,如果token存在直接跳转
    if (store.getters['tenant/getAccessToken']) {
      next()
    } else {
      // 否则跳转到login登录页面
      next({
        path: '/login',
        // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    // 如果不需要登录，则直接跳转到对应页面
    next()
  }
})

export default router

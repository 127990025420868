<template>
  <div class="common-tabs">
    <el-tabs type="card" v-model="tabValue" @tab-click="selectTab" @tab-remove="removeTab">
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="item.route"
        :label="item.title"
        :name="item.route"
        :closable="index !== 0"
      >
        <template #label v-if="index === 0">
          <span>
            <el-icon>
              <HomeFilled />
            </el-icon>
            {{ item.title }}
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { HomeFilled } from '@element-plus/icons-vue'

export default {
  name: 'HomeTabs',
  components: {
    HomeFilled
  },
  data() {
    return {
      tabValue: '',
      tabs: [
        {
          title: '管理中心',
          route: '/home/dashboard'
        }
      ]
    }
  },
  methods: {
    selectTab(tab) {
      this.$router.push(tab.props.name)
      this.$emit('selectTab', { title: tab.props.label, route: tab.props.name })
    },
    removeTab(route) {
      // 保留默认
      if (this.tabs[0].route !== route) {
        this.tabs.splice(
          this.tabs.findIndex((e) => e.route === route),
          1
        )
      }
      // 只剩管理中心,则跳转
      if (this.tabs.length === 1) {
        this.tabValue = this.tabs[0].route
        this.$router.push(this.tabs[0].route)
      } else {
        this.tabValue = this.tabs[1].route
      }
    },
    addTab(tab) {
      const i = this.tabs.findIndex((e) => e.route === tab.route)
      if (i === -1) {
        // 不存在
        this.tabs.splice(1, 0, { title: tab.title, route: tab.route })
      }
      this.tabValue = tab.route
    },
    initTab(route, tabs) {
      this.tabValue = route
      this.tabs = tabs
    }
  }
}
</script>

<style scoped>
.common-tabs >>> .el-tabs__header {
  margin: 0;
}
</style>

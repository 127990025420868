<template>
  <div id="app">
    <router-view to="/"></router-view>
  </div>
</template>

<style>
#app,
html,
body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #f0f2f5;
  /*cursor: url("../src/assets/img/15.png"), auto;*/
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #eeeeee;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background: #fe9600;
}

::selection {
  background: #807dd4;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  user-select: none;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    '微软雅黑', Arial, sans-serif;
}

.txt_blue {
  user-select: none;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
}

ul,
li {
  list-style: none;
}

a {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
}

/*markdown标题导航样式*/
.title-style {
  cursor: pointer;
  font-size: 16px;
  color: #6e7177;
}

.title-style-select {
  cursor: pointer;
  font-size: 16px;
  color: orange;
}

.title-style p:hover {
  color: orange;
}
</style>

<template>
  <div class="common-header">common-header</div>
</template>

<script>
export default {
  name: 'HomeHeader',
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {}
}
</script>

<style scoped></style>

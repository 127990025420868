export default {
  namespaced: true, // 为了解决不同模块命名冲突的问题
  // 全局参数 获取属性时同样加上模块名 this.$store.state.xxx.user
  state: {
    cacheKey: {
      jsonContent: 'json_content',
      toolJson: 'tool_json'
    }
  },
  // set方法 this.$store.commit('模块名/方法', 值)
  mutations: {
    setJsonContent: (state, jsonData) => {
      localStorage.setItem(state.cacheKey.jsonContent, jsonData)
    },
    setToolJson: (state, index) => {
      localStorage.setItem(state.cacheKey.toolJson, index)
    }
  },
  // get方法 this.$store.getters['模块/方法']
  getters: {
    getJsonContent: (state) => {
      return localStorage.getItem(state.cacheKey.jsonContent)
    },
    getToolJson: (state) => {
      return localStorage.getItem(state.cacheKey.toolJson)
    }
  },
  // 异步方法 this.$store.dispatch(‘模块/方法名称’, 值)
  actions: {
    syncJsonContent: (context, jsonData) => {
      context.commit('setJsonContent', jsonData)
    },
    syncToolJson: (context, jsonData) => {
      context.commit('setToolJson', jsonData)
    }
  }
}

// markdown编辑器
import VueMarkdownEditor from '@kangc/v-md-editor/lib/codemirror-editor'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'
// 快捷复制代码
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css'
// 高亮代码行
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index'
// 代码行号
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index'
// mermaid
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn'
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css'
// todolist
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index'
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css'
// markdown预览
import VueMarkdownPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/codemirror-editor.css'
import '@kangc/v-md-editor/lib/style/preview.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
// 编辑器样式
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
// codemirror 编辑器的相关资源
import Codemirror from 'codemirror'
// placeholder
import 'codemirror/addon/display/placeholder'
// edit
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/matchbrackets'
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/scroll/simplescrollbars.css'
// active-line
import 'codemirror/addon/selection/active-line'
// style
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/javascript/javascript'
// mode
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/vue/vue'
// element plus框架
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import Prism from 'prismjs'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// markdown编辑器
VueMarkdownEditor.Codemirror = Codemirror
VueMarkdownEditor.use(vuepressTheme, { Prism })
  .use(createLineNumbertPlugin())
  .use(createHighlightLinesPlugin())
  .use(createCopyCodePlugin())
  .use(createTodoListPlugin())
  .use(createMermaidPlugin())

// markdown预览
VueMarkdownPreview.use(vuepressTheme, { Prism })
  .use(createLineNumbertPlugin())
  .use(createTodoListPlugin())
  .use(createCopyCodePlugin())
  .use(createMermaidPlugin())

// s注册到vue
createApp(App)
  .use(store)
  .use(router)
  .use(VueMarkdownEditor)
  .use(VueMarkdownPreview)
  .use(ElementPlus, { locale: zhCn })
  .mount('#app')

<template>
  <div class="common-menu">
    <el-menu
      :router="true"
      :default-active="defaultActive"
      :default-openeds="defaultOpens"
      @select="handleSelect"
    >
      <el-sub-menu index="1">
        <template #title>
          <icon icon-name="Document"></icon>
          <span>文章中心</span>
        </template>
        <el-menu-item index="笔记管理" route="/home/article/notes">笔记管理</el-menu-item>
        <el-menu-item index="草稿管理" route="/home/article/manuscript">草稿管理</el-menu-item>
        <el-menu-item index="目录管理" route="/home/article/catalog">目录管理</el-menu-item>
        <el-menu-item index="标签管理" route="/home/article/tag">标签管理</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="2">
        <template #title>
          <icon icon-name="IconMenu"></icon>
          <span>资源管理</span>
        </template>
        <el-menu-item index="回收站" route="/home/resource/recycle">资源回收</el-menu-item>
        <el-menu-item index="图片管理" route="/home/resource/image">图片管理</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>
          <icon icon-name="Location"></icon>
          <span>Navigator Two</span>
        </template>
        <el-menu-item index="2-1">item Two</el-menu-item>
        <el-menu-item index="2-2">item Two</el-menu-item>
        <el-menu-item index="2-3">item Two</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="4">
        <template #title>
          <icon icon-name="Setting"></icon>
          <span>Navigator Four</span>
        </template>
        <el-menu-item index="1-1">item one</el-menu-item>
        <el-menu-item index="1-2">item one</el-menu-item>
        <el-menu-item index="1-3">item one</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import icon from '@/components/icon'

export default {
  name: 'HomeMenu',
  components: {
    icon
  },
  data() {
    return {
      defaultActive: '',
      defaultOpens: ['1', '2', '3', '4']
    }
  },
  methods: {
    handleSelect(index, path, item) {
      this.defaultActive = index
      this.$emit('selectMenu', { index: index, route: item.route })
    },
    linkSelectMenu(index) {
      this.defaultActive = index
    }
  },
  computed: {},
  watch: {},
  mounted() {}
}
</script>

<style scoped></style>

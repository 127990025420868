import router from '@/router/index'

export default {
  namespaced: true, // 为了解决不同模块命名冲突的问题
  // 全局参数 获取属性时同样加上模块名 this.$store.state.xxx.user
  state: {
    cacheKey: {
      accessToken: 'access_token',
      refreshToken: 'refresh_token',
      tokenType: 'token_type'
    }
  },
  // set方法 this.$store.commit('模块名/方法', 值)
  mutations: {
    setAccessToken: (state, accessToken) => {
      localStorage.setItem(state.cacheKey.accessToken, accessToken)
    },
    setRefreshToken: (state, refreshToken) => {
      localStorage.setItem(state.cacheKey.refreshToken, refreshToken)
    },
    setTokenType: (state, tokenType) => {
      localStorage.setItem(state.cacheKey.tokenType, tokenType)
    }
  },
  // get方法 this.$store.getters['模块/方法']
  getters: {
    getAccessToken: (state) => {
      return localStorage.getItem(state.cacheKey.accessToken)
    },
    getRefreshToken: (state) => {
      return localStorage.getItem(state.cacheKey.refreshToken)
    },
    getTokenType: (state) => {
      return localStorage.getItem(state.cacheKey.tokenType)
    }
  },
  // 异步方法 this.$store.dispatch(‘模块/方法名称’, 值)
  actions: {
    syncClearToken: () => {
      localStorage.clear()
      router.replace({ path: '/login', query: { redirect: router.currentRoute.value.fullPath } })
    },
    syncSetToken: (context, data) => {
      context.commit('setAccessToken', data.accessToken)
      context.commit('setRefreshToken', data.refreshToken)
      context.commit('setTokenType', data.tokenType)
    }
  }
}

<template>
  <div class="component-icon">
    <el-icon :size="iconSize" :color="iconColor" style="vertical-align: middle">
      <component :is="iconName"></component>
    </el-icon>
    <span
      v-if="'' !== iconTitle"
      :style="{ 'vertical-align': 'middle', 'margin-left': iconSpace + 'px' }"
    >
      {{ iconTitle }}
    </span>
  </div>
</template>

<script>
import {
  ChatDotSquare,
  Comment,
  Compass,
  Delete,
  Document,
  Download,
  Edit,
  EditPen,
  Flag,
  FolderOpened,
  HelpFilled,
  HomeFilled,
  Location,
  Menu as IconMenu,
  Notebook,
  Plus,
  Position,
  Refresh,
  Search,
  Setting,
  Tools,
  View,
  Promotion,
  CloseBold,
  Select,
  Timer,
  UploadFilled
} from '@element-plus/icons-vue'

export default {
  name: 'Icon',
  components: {
    Location,
    Document,
    IconMenu,
    Setting,
    Edit,
    Search,
    EditPen,
    Refresh,
    Delete,
    Compass,
    Flag,
    Notebook,
    FolderOpened,
    Plus,
    Position,
    View,
    HomeFilled,
    Tools,
    Download,
    Comment,
    HelpFilled,
    ChatDotSquare,
    Promotion,
    CloseBold,
    Select,
    Timer,
    UploadFilled
  },
  props: {
    iconName: {
      type: String,
      default: ''
    },
    iconSize: {
      type: Number,
      default: 20
    },
    iconColor: {
      type: String,
      default: ''
    },
    iconTitle: {
      type: String,
      default: ''
    },
    iconSpace: {
      type: Number,
      default: 10
    }
  }
}
</script>

<style scoped></style>

export default {
  namespaced: true, // 为了解决不同模块命名冲突的问题
  // 全局参数 获取属性时同样加上模块名 this.$store.state.xxx.user
  state: {
    cacheKey: {
      homeCenterRouter: 'home_center_router',
      notesSearch: 'notes_search'
    }
  },
  // set方法 this.$store.commit('模块名/方法', 值)
  mutations: {
    setHomeCenterRouter: (state, accessToken) => {
      localStorage.setItem(state.cacheKey.homeCenterRouter, accessToken)
    },
    setNotesSearch: (state, param) => {
      localStorage.setItem(state.cacheKey.notesSearch, param)
    }
  },
  // get方法 this.$store.getters['模块/方法']
  getters: {
    getHomeCenterRouter: (state) => {
      return localStorage.getItem(state.cacheKey.homeCenterRouter)
    },
    getNotesSearch: (state) => {
      return localStorage.getItem(state.cacheKey.notesSearch)
    }
  },
  // 异步方法 this.$store.dispatch(‘模块/方法名称’, 值)
  actions: {
    syncHomeCenterRouter: (context, data) => {
      context.commit('setHomeCenterRouter', data)
    },
    syncNotesSearch: (context, data) => {
      context.commit('setNotesSearch', data)
    }
  }
}

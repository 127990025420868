<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <home-header></home-header>
      </el-header>
      <el-container>
        <el-aside width="250px">
          <home-menu @selectMenu="selectMenu" ref="menus"></home-menu>
        </el-aside>
        <el-container>
          <el-footer>
            <home-tabs @selectTab="selectTab" ref="tabs"></home-tabs>
          </el-footer>
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import header from '@/layout/components/header'
import menu from '@/layout/components/menu'
import tabs from '@/layout/components/tabs'

export default {
  name: 'Layout',
  components: {
    'home-header': header,
    'home-menu': menu,
    'home-tabs': tabs
  },
  data() {
    return {}
  },
  methods: {
    selectMenu(menu) {
      this.$refs.tabs.addTab({ title: menu.index, route: menu.route })
      this.homeRouter(menu.index, menu.route)
    },
    selectTab(tab) {
      this.$refs.menus.linkSelectMenu(tab.title)
      this.homeRouter(tab.title, tab.route)
    },
    homeRouter(title, route) {
      const showData = {
        title: title,
        route: route,
        tabs: this.$refs.tabs.tabs
      }
      this.$store.dispatch('home/syncHomeCenterRouter', JSON.stringify(showData))
    }
  },
  mounted() {
    const showData = JSON.parse(this.$store.getters['home/getHomeCenterRouter'])
    if (showData) {
      this.$refs.menus.linkSelectMenu(showData.title)
      this.$refs.tabs.initTab(showData.route, showData.tabs)
      this.$router.replace(showData.route)
    }
  }
}
</script>

<style scoped>
.common-layout {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.common-layout .el-container {
  height: 100%;
}

.common-layout .el-header {
  background-color: rgb(0, 21, 41);
  color: #ffffff;
}

.common-layout >>> .el-footer {
  --el-footer-padding: 0;
  --el-footer-height: 40px;
  background-color: #ffffff;
}

.common-layout .el-aside {
  background-color: #ffffff;
}

.common-layout .el-main {
  background-color: #e9eef3;
  min-width: 700px;
  min-height: 800px;
}
</style>
